import {
  CustomFieldDate,
  CustomFieldDatetime,
  CustomFieldFloat,
  CustomFieldInt,
  CustomFieldSelect,
  CustomFieldText,
  getCustomFieldDateValues,
  getCustomFieldDatetimeValues,
  getCustomFieldFloatValues,
  getCustomFieldIntValues,
  getCustomFieldSelectValues,
  getCustomFieldTextValues,
} from '@/common/components/CustomFieldItem';
import {
  generateNewCustomFieldDateValues,
  generateNewCustomFieldDatetimeValues,
  generateNewCustomFieldFloatValues,
  generateNewCustomFieldIntValues,
  generateNewCustomFieldSelectValues,
  generateNewCustomFieldTextValues,
} from '@/common/components/CustomFieldItem';
import DetailDisplayFileItem from '@/common/components/DetailDisplayFileItem';
import DetailDisplayItem from '@/common/components/DetailDisplayItem';
import DisplaySection, {
  type DetailDisplaySectionHandle,
} from '@/common/components/DisplaySection';
import { OverviewTextEditor } from '@/common/components/OverviewTextEditor';
import { generateOptionToSelectItem } from '@/common/components/customFields';
import { CheckListTriggeredAlertIcon } from '@/components/checkLists/CheckListTriggeredAlertIcon';
import { CustomFieldAttachmentType } from '@/components/workOrders/customFields/CustomFieldAttachmentType';
import CustomFieldNote from '@/components/workOrders/customFields/CustomFieldNote';
import CustomFieldStamp from '@/components/workOrders/customFields/CustomFieldStamp';
import {
  ICheckListCustomField,
  ICheckListTemplateItem,
  ICheckListTemplateSection,
} from '@/modules/checkList';

import { useToast } from '@/utils/atoms/toast';
import { isSection } from '@/utils/checkListItems/checkListItems';
import { deepCopy } from '@/utils/copy/copy';
import { CheckListFormValueType, getValueByType } from '@/utils/customFields/checkListCustomFields';
import { useFiles } from '@/utils/file/useFiles';
import useTranslation from '@/utils/i18n/useTranslation';
import { checkActivatedTrigger } from '@/utils/trigger/trigger';
import { Box, Button, Flex, FormControl, FormLabel, HStack } from '@chakra-ui/react';
import { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

type WorkOrderCheckListCustomFieldListProps = {
  onChangeCheckListValue?: (checkListFormValue: CheckListFormValueType) => void;
  items: ICheckListTemplateItem[];
  checkListFormValue: CheckListFormValueType;
  isEdit: boolean;
  preview?: boolean;
  name?: string;
  description?: string;
};

const WorkOrderCheckListCustomFieldList: FC<WorkOrderCheckListCustomFieldListProps> = (
  props: WorkOrderCheckListCustomFieldListProps
) => {
  const {
    items,
    checkListFormValue,
    onChangeCheckListValue,
    isEdit,
    preview = false,
    name,
    description,
  } = props;

  const { t, t_errors, t_toasts } = useTranslation();
  const { toast } = useToast();

  const { getFileUploadUrls, setFileUrlToSrcFiles } = useFiles();

  const [customFieldAttachmentFilesForShow, setCustomFieldAttachmentFilesForShow] = useState<
    CustomFieldAttachmentType[]
  >([]);
  useEffect(() => {
    setFileUrlToSrcFiles(checkListFormValue.customFieldAttachments || []).then((attachmentFiles) =>
      setCustomFieldAttachmentFilesForShow(attachmentFiles)
    );
  }, [checkListFormValue, setFileUrlToSrcFiles]);

  const onChangeCheckListCustomFiledValue = useCallback(
    (checkListFormValue: CheckListFormValueType) => {
      if (preview) return;
      if (!onChangeCheckListValue)
        throw new Error(t_errors('item-is-not-defined', { item: 'onChangeCheckListValue' }));
      onChangeCheckListValue(checkListFormValue);
    },
    [preview, onChangeCheckListValue, t_errors]
  );

  const onChangeNoteChanged = useCallback(
    (input: { customFieldId: number; note: string }) => {
      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      const customFieldNoteValue = copiedCheckListFormValues.customFieldNotes.find(
        (entry) => entry.customFieldId === input.customFieldId
      );

      if (customFieldNoteValue) {
        customFieldNoteValue.note = input.note;
      }

      const customFieldNotes = customFieldNoteValue
        ? [...copiedCheckListFormValues.customFieldNotes]
        : [...copiedCheckListFormValues.customFieldNotes, { ...input }];

      copiedCheckListFormValues.customFieldNotes = customFieldNotes;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [checkListFormValue, onChangeCheckListCustomFiledValue]
  );

  const onAddCheckListCustomFieldAttachment = (
    customFieldId: number,
    fileId: string,
    contentType: string,
    name: string
  ) => {
    if (preview) return;
    if (!isEdit) return;

    const copiedCheckListFormValues = deepCopy(checkListFormValue);
    const input = { customFieldId, name, contentType, fileId };

    copiedCheckListFormValues.customFieldAttachments = [
      ...copiedCheckListFormValues.customFieldAttachments,
      ...[input],
    ];
    onChangeCheckListCustomFiledValue(copiedCheckListFormValues);

    toast({
      title: t_toasts('success.file.file-created'),
      status: 'success',
    });
  };

  const onDeleteCheckListCustomFieldAttachment = (customFieldId: number, fileId: string) => {
    if (preview) return;
    if (!isEdit) return;

    const copiedCheckListFormValues = deepCopy(checkListFormValue);
    copiedCheckListFormValues.customFieldAttachments =
      copiedCheckListFormValues.customFieldAttachments.filter((entry) => {
        return !(entry.customFieldId === customFieldId && entry.fileId === fileId);
      });
    onChangeCheckListCustomFiledValue(copiedCheckListFormValues);

    toast({
      title: t_toasts('success.file.file-deleted'),
      status: 'success',
    });
  };

  const onChangeTextChanged = useCallback(
    (input: { customFieldId: number; value: string }) => {
      const newCustomFieldTextValues = generateNewCustomFieldTextValues(
        checkListFormValue.customFieldTextValues,
        input
      );

      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      copiedCheckListFormValues.customFieldTextValues = newCustomFieldTextValues;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [checkListFormValue, onChangeCheckListCustomFiledValue]
  );

  const onChangeDatetimeChanged = useCallback(
    (input: { customFieldId: number; value: Date | null }) => {
      const newCustomFieldDatetimeValues = generateNewCustomFieldDatetimeValues(
        checkListFormValue.customFieldDatetimeValues,
        input
      );

      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      copiedCheckListFormValues.customFieldDatetimeValues = newCustomFieldDatetimeValues;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [onChangeCheckListCustomFiledValue, checkListFormValue]
  );

  const onChangeDateChanged = useCallback(
    (input: { customFieldId: number; value: Date | null }) => {
      const newCustomFieldDateValues = generateNewCustomFieldDateValues(
        checkListFormValue.customFieldDateValues,
        input
      );

      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      copiedCheckListFormValues.customFieldDateValues = newCustomFieldDateValues;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [onChangeCheckListCustomFiledValue, checkListFormValue]
  );

  const onChangeFloatChanged = useCallback(
    (input: { customFieldId: number; value: number | null }) => {
      const newCustomFieldFloatValues = generateNewCustomFieldFloatValues(
        checkListFormValue.customFieldFloatValues,
        input
      );

      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      copiedCheckListFormValues.customFieldFloatValues = newCustomFieldFloatValues;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [onChangeCheckListCustomFiledValue, checkListFormValue]
  );

  const onChangeIntChanged = useCallback(
    (input: { customFieldId: number; value: number | null }) => {
      const newCustomFieldIntValues = generateNewCustomFieldIntValues(
        checkListFormValue.customFieldIntValues,
        input
      );

      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      copiedCheckListFormValues.customFieldIntValues = newCustomFieldIntValues;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [onChangeCheckListCustomFiledValue, checkListFormValue]
  );

  const onChangeSelectChanged = useCallback(
    (input: { customFieldId: number; values: number[] }) => {
      const newCustomFieldSelectValues = generateNewCustomFieldSelectValues(
        checkListFormValue.customFieldSelectValues,
        input
      );

      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      copiedCheckListFormValues.customFieldSelectValues = newCustomFieldSelectValues;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [onChangeCheckListCustomFiledValue, checkListFormValue]
  );

  const onChangeStampChanged = useCallback(
    (input: {
      customFieldId: number;
      stampedById?: string;
      stampedByName?: string;
      stampedAt?: Date;
    }) => {
      const copiedCheckListFormValues = deepCopy(checkListFormValue);
      const index = copiedCheckListFormValues.customFieldStampValues.findIndex(
        (entry) => entry.customFieldId === input.customFieldId
      );

      const { customFieldId, stampedById, stampedByName, stampedAt } = input;

      if (stampedById === undefined) {
        // indexが0の場合は、customFieldSelectValuesを空にする
        copiedCheckListFormValues.customFieldStampValues.splice(index, 1);
        onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
        return;
      }

      if (!stampedByName) throw new Error('stampedByName is undefined');
      if (!stampedAt) throw new Error('stampedAt is undefined');

      if (index >= 0) {
        copiedCheckListFormValues.customFieldStampValues[index].stampedBy.id = stampedById;
        copiedCheckListFormValues.customFieldStampValues[index].stampedBy.name = stampedByName;
        copiedCheckListFormValues.customFieldStampValues[index].stampedAt = stampedAt;

        onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
        return;
      }

      const customFieldStampValues = [
        ...copiedCheckListFormValues.customFieldStampValues,
        {
          customFieldId: customFieldId,
          stampedBy: { name: stampedByName, id: stampedById },
          stampedAt,
        },
      ];
      copiedCheckListFormValues.customFieldStampValues = customFieldStampValues;
      onChangeCheckListCustomFiledValue(copiedCheckListFormValues);
    },
    [onChangeCheckListCustomFiledValue, checkListFormValue]
  );

  const buildCustomField = (customField: ICheckListCustomField) => {
    let customFieldElement: null | ReactElement = null;

    switch (customField.type) {
      case 'text':
        const customFieldTextValue = getCustomFieldTextValues(
          customField.id,
          checkListFormValue.customFieldTextValues
        );
        customFieldElement = (
          <CustomFieldText
            customFieldId={customField.id}
            value={customFieldTextValue}
            onChange={onChangeTextChanged}
            disabled={preview}
            required={false}
          />
        );
        break;
      case 'datetime':
        const customFieldDatetimeValue = getCustomFieldDatetimeValues(
          customField.id,
          checkListFormValue.customFieldDatetimeValues
        );
        customFieldElement = (
          <CustomFieldDatetime
            customFieldId={customField.id}
            value={customFieldDatetimeValue}
            onChange={onChangeDatetimeChanged}
            disabled={preview}
          />
        );
        break;
      case 'date':
        const customFieldDateValue = getCustomFieldDateValues(
          customField.id,
          checkListFormValue.customFieldDateValues
        );
        customFieldElement = (
          <CustomFieldDate
            customFieldId={customField.id}
            value={customFieldDateValue}
            onChange={onChangeDateChanged}
            disabled={preview}
          />
        );
        break;
      case 'select':
        const selectedOptionIds = getCustomFieldSelectValues(
          customField.id,
          checkListFormValue.customFieldSelectValues
        );
        const options = customField.options
          .filter((option) => selectedOptionIds.includes(option.id))
          .map(generateOptionToSelectItem);

        customFieldElement = (
          <CustomFieldSelect
            values={selectedOptionIds}
            customFieldId={customField.id}
            options={options}
            assetId={undefined}
            defaults={customField.defaults}
            onChange={onChangeSelectChanged}
            disabled={preview}
          />
        );
        break;
      case 'float':
        const customFieldFloatValue = getCustomFieldFloatValues(
          customField.id,
          checkListFormValue.customFieldFloatValues
        );
        customFieldElement = (
          <CustomFieldFloat
            customFieldId={customField.id}
            value={customFieldFloatValue}
            onChange={onChangeFloatChanged}
            disabled={preview}
          />
        );
        break;

      case 'int':
        const customFieldIntValue = getCustomFieldIntValues(
          customField.id,
          checkListFormValue.customFieldIntValues
        );
        customFieldElement = (
          <CustomFieldInt
            disabled={preview}
            customFieldId={customField.id}
            value={customFieldIntValue}
            onChange={onChangeIntChanged}
          />
        );
        break;

      case 'stamp':
        const customFieldStampValue = checkListFormValue.customFieldStampValues.find(
          (value) => value.customFieldId === customField.id
        );

        customFieldElement = (
          <CustomFieldStamp
            disabled={preview}
            customFieldId={customField.id}
            stampedById={customFieldStampValue ? customFieldStampValue.stampedBy.id : undefined}
            stampedAt={customFieldStampValue ? customFieldStampValue.stampedAt : undefined}
            stampedByName={customFieldStampValue ? customFieldStampValue.stampedBy.name : undefined}
            onChange={onChangeStampChanged}
          />
        );
        break;
    }

    const customFieldNote = checkListFormValue.customFieldNotes.find(
      (customFieldNote) => customFieldNote.customFieldId === customField.id
    );
    const customFieldAttachments = checkListFormValue.customFieldAttachments.filter(
      (attachment) => {
        return attachment.customFieldId === customField.id;
      }
    );

    const activatedTriggers = checkActivatedTrigger(customField, checkListFormValue);

    return (
      <Box
        key={customField.id}
        bg={activatedTriggers.length > 0 ? 'red.50' : 'neutral.0'}
        border='1px solid'
        borderColor={activatedTriggers.length > 0 ? 'red.500' : 'neutral.0'}
        borderRadius='md'
        position='relative'
        px={4}
        py={2}
        mx={2}
        my={1}
      >
        <FormControl key={customField.id}>
          <Flex justify='space-between' align='center'>
            <FormLabel color='neutral.800'>{customField.label}</FormLabel>
            <CheckListTriggeredAlertIcon
              activatedTriggers={activatedTriggers}
              options={customField.options}
            />
          </Flex>
          {customField && (
            <Box my={2}>
              <OverviewTextEditor
                value={customField.description}
                attachments={customField.descriptionAttachments ?? []}
                mode='display'
              />
            </Box>
          )}

          {customFieldElement}
          <CustomFieldNote
            disabled={preview}
            customFieldId={customField.id}
            onChange={onChangeNoteChanged}
            onAddAttachment={onAddCheckListCustomFieldAttachment}
            onDeleteAttachment={onDeleteCheckListCustomFieldAttachment}
            getFileUploadUrls={getFileUploadUrls}
            note={customFieldNote ? customFieldNote.note : undefined}
            attachments={customFieldAttachments}
          />
        </FormControl>
      </Box>
    );
  };

  const buildCustomFieldReadonly = (customField: ICheckListCustomField) => {
    const value = getValueByType(customField, checkListFormValue);
    let customFieldElement: null | ReactElement = null;

    const activatedTriggers = checkActivatedTrigger(customField, checkListFormValue);

    if (customField.type === 'stamp') {
      const customFieldStampValue = checkListFormValue.customFieldStampValues.find(
        (value) => value.customFieldId === customField.id
      );

      if (customFieldStampValue && customFieldStampValue.stampedBy.id) {
        customFieldElement = (
          <DetailDisplayItem key={customField.id} label={customField.label} vertical={true}>
            <CustomFieldStamp
              disabled={true}
              customFieldId={customField.id}
              stampedById={customFieldStampValue ? customFieldStampValue.stampedBy.id : undefined}
              stampedAt={customFieldStampValue ? customFieldStampValue.stampedAt : undefined}
              stampedByName={
                customFieldStampValue ? customFieldStampValue.stampedBy.name : undefined
              }
              onChange={onChangeStampChanged}
            />
          </DetailDisplayItem>
        );
      }
    } else if (customField.type === 'text') {
      customFieldElement = (
        <DetailDisplayItem label={customField.label} value={value} vertical={true} />
      );
    } else {
      customFieldElement = (
        <DetailDisplayItem
          label={customField.label}
          value={value}
          suffix={
            <CheckListTriggeredAlertIcon
              activatedTriggers={activatedTriggers}
              options={customField.options}
            />
          }
        />
      );
    }

    const customFieldNote = checkListFormValue.customFieldNotes.find(
      (customFieldNote) => customFieldNote.customFieldId === customField.id
    );
    const attachments = customFieldAttachmentFilesForShow.filter(
      (a) => a.customFieldId === customField.id
    );

    return (
      <Box key={customField.id}>
        {customFieldElement}

        {customFieldNote && (
          <DetailDisplayItem
            label={`${customField.label}:${t('remark.remarks')}`}
            value={customFieldNote.note}
          />
        )}
        {customFieldAttachmentFilesForShow && (
          <DetailDisplayFileItem
            label={`${customField.label}:${t('remark.attachments')}`}
            files={attachments}
          />
        )}
      </Box>
    );
  };

  const hasSection = items.some((item) => isSection(item));
  const sectionRefs = useRef<Map<number, DetailDisplaySectionHandle>>(new Map());
  const registerSectionRef = (id: number, node: DetailDisplaySectionHandle | null) => {
    const map = sectionRefs.current;
    if (node) {
      map.set(id, node);
    } else {
      map.delete(id);
    }
  };

  return (
    <Box mx={2}>
      <FormLabel data-testid='check-list-template-detail-name' fontWeight='bold'>
        {name ? name : t('pages.check-list')}
      </FormLabel>
      <DetailDisplayItem
        data-testid='check-list-template-detail-description'
        value={description}
        vertical={true}
      />
      {hasSection && (
        <HStack justifyContent='end' pr={4} gap={4} pt={description ? 2 : 0}>
          <Button
            onClick={() =>
              Array.from(sectionRefs.current.values()).forEach((it) => it.openAccordion())
            }
            variant='link'
            colorScheme='primary'
          >
            {t('actions.open-all-sections')}
          </Button>
          <Button
            onClick={() =>
              Array.from(sectionRefs.current.values()).forEach((it) => it.closeAccordion())
            }
            variant='link'
            colorScheme='primary'
          >
            {t('actions.close-all-sections')}
          </Button>
        </HStack>
      )}
      {isEdit ? (
        <Box borderLeft='2px' borderLeftColor='primary.500'>
          {items.map((item) => {
            if (isSection(item)) {
              const section = item as ICheckListTemplateSection;
              return (
                <DisplaySection
                  key={`section-${section.id}`}
                  section={section}
                  ref={(node) => registerSectionRef(section.id, node)}
                >
                  {section.items.map((customField) => {
                    return buildCustomField(customField);
                  })}
                </DisplaySection>
              );
            } else {
              return buildCustomField(item as ICheckListCustomField);
            }
          })}
        </Box>
      ) : (
        <Box bg='neutral.0' borderRadius='md'>
          {items.map((item) => {
            if (isSection(item)) {
              const section = item as ICheckListTemplateSection;
              return (
                <DisplaySection
                  key={section.id}
                  section={section}
                  ref={(node) => registerSectionRef(section.id, node)}
                >
                  {section.items.map((customField) => {
                    return buildCustomFieldReadonly(customField);
                  })}
                </DisplaySection>
              );
            } else {
              return buildCustomFieldReadonly(item as ICheckListCustomField);
            }
          })}
        </Box>
      )}
    </Box>
  );
};

export default WorkOrderCheckListCustomFieldList;
