import { Maybe } from '@/common/types';
import { useApplicationContext } from '@/context/ApplicationContext';
import { useAuthContext } from '@/context/AuthContext';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import { useToast } from '@/utils/atoms/toast';
import { formatDateToYYYYMD } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { Divider, Flex, Text } from '@chakra-ui/react';
import type { FC } from 'react';

type CustomFieldStampProps = {
  onChange: (input: {
    customFieldId: number;
    stampedById: Maybe<string>;
    stampedByName: Maybe<string>;
    stampedAt: Maybe<Date>;
  }) => void;
  stampedById: Maybe<string>;
  stampedByName: Maybe<string>;
  stampedAt: Maybe<Date>;
  customFieldId: number;
  disabled?: boolean;
};

const CustomFieldStamp: FC<CustomFieldStampProps> = (props: CustomFieldStampProps) => {
  const {
    onChange,
    stampedAt,
    stampedByName,
    stampedById,
    customFieldId,
    disabled = false,
  } = props;

  const { toast } = useToast();
  const { confirm } = useConfirmModal();
  const { user } = useAuthContext();
  const { me } = useApplicationContext();
  const { t, t_toasts } = useTranslation();

  const onClick = async () => {
    if (disabled) return;
    if (!user) return;
    if (stampedById && user.uid !== stampedById) {
      toast({
        title: t_toasts('failed.only-logged-in-can-sign'),
        status: 'error',
      });
      return;
    }

    if (stampedById && user.uid === stampedById) {
      const result = await confirm(
        t('confirmation.cancel-sign'),
        undefined,
        t('confirmation.cancel')
      );

      if (result) {
        onChange({
          customFieldId,
          stampedById: undefined,
          stampedByName: undefined,
          stampedAt: undefined,
        });
      }
      return;
    }

    if (!me) throw new Error('me is undefined');
    onChange({
      customFieldId,
      stampedById: me.id,
      stampedByName: me.name,
      stampedAt: new Date(),
    });
  };

  return (
    <Flex
      border='2px double #f00'
      color='#f00'
      borderRadius={50}
      minWidth='88px'
      minHeight='88px'
      maxWidth='88px'
      maxHeight='88px'
      justifyContent='center'
      alignItems='center'
      direction='column'
      onClick={onClick}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      my={2}
      mx='auto'
    >
      <Text
        fontSize='2xs'
        fontWeight='bold'
        textOverflow='ellipsis'
        overflow='hidden'
        whiteSpace='nowrap'
        maxWidth='72px'
      >
        {stampedByName}
      </Text>
      <Divider my='8px' />
      <Text fontSize='2xs'>{stampedAt ? formatDateToYYYYMD(stampedAt) : ''}</Text>
    </Flex>
  );
};

export default CustomFieldStamp;
