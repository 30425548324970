import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import { OverviewTextEditor } from './OverviewTextEditor';

export type DetailDisplaySectionProps = {
  section: {
    id: number;
    label: string;
    description?: string | null;
  };
  children: ReactNode;
};

export type DetailDisplaySectionHandle = {
  openAccordion: () => void;
  closeAccordion: () => void;
};

const DisplaySection = forwardRef<DetailDisplaySectionHandle, DetailDisplaySectionProps>(
  function DisplaySection(props, ref) {
    const { section, children } = props;
    const { label, description } = section;
    const [index, setIndex] = useState<number | number[]>([]);

    useImperativeHandle(ref, () => ({
      openAccordion: () => setIndex([0]),
      closeAccordion: () => setIndex([]),
    }));

    return (
      <Box bg='neutral.0' borderRadius='md' py={2} my={1}>
        <Accordion
          borderLeft='2px'
          borderLeftColor='primary.300'
          allowToggle
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  {label}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <OverviewTextEditor value={description} mode='display' />
              {children}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  }
);

export default DisplaySection;
