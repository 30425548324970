import { DocumentProps, FileUploadButton } from '@/common/components/FileUploader';
import MediaSwiperWrapper from '@/common/components/MediaSwiperWrapper';
import { Maybe } from '@/common/types';
import { CustomFieldAttachmentType } from '@/components/workOrders/customFields/CustomFieldAttachmentType';
import { ICustomFieldAttachment } from '@/modules/checkList';
import { FileContentType, FileIdAndUrl } from '@/utils/file/type';
import { useFiles } from '@/utils/file/useFiles';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button, Flex, Textarea } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

type CustomFieldNoteProps = {
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  onChange: (input: { customFieldId: number; note: string }) => void;
  onAddAttachment?: (
    customFieldId: number,
    fileId: string,
    contentType: string,
    name: string
  ) => void;
  onDeleteAttachment?: (customFieldId: number, fileId: string) => void;
  note: Maybe<string>;
  attachments?: ICustomFieldAttachment[];
  customFieldId: number;
  disabled?: boolean;
};

const CustomFieldNote: FC<CustomFieldNoteProps> = (props: CustomFieldNoteProps) => {
  const {
    getFileUploadUrls,
    onChange,
    onAddAttachment,
    onDeleteAttachment,
    note,
    attachments = [],
    customFieldId,
    disabled = false,
  } = props;

  const [isNoteDisplayed, setIsNoteDisabled] = useState<boolean>(!!note);
  const [localAttachments, setLocalAttachments] = useState<ICustomFieldAttachment[]>(attachments);
  const [attachmentFiles, setAttachmentFiles] = useState<CustomFieldAttachmentType[]>([]);
  const { t } = useTranslation();
  const { setFileUrlToSrcFiles, cacheFileSrc } = useFiles();

  useEffect(() => {
    setFileUrlToSrcFiles(localAttachments || []).then((attachmentFiles) =>
      setAttachmentFiles(attachmentFiles)
    );
  }, [localAttachments, setFileUrlToSrcFiles]);

  const onClick = () => {
    if (isNoteDisplayed) {
      onChange({
        customFieldId,
        note: '',
      });
    }
    setIsNoteDisabled(!isNoteDisplayed);
  };

  const onAddFile = ({ id, fileData, file }: DocumentProps) => {
    if (id && fileData) {
      const newAttachment: ICustomFieldAttachment = {
        customFieldId,
        fileId: id,
        name: file.name,
        contentType: file.type,
      };

      setLocalAttachments([...localAttachments, newAttachment]);
      cacheFileSrc(id, fileData);
      if (onAddAttachment) onAddAttachment(customFieldId, id, file.type, file.name);
    }
  };

  const onRemoveFile = (fileId: string) => {
    setLocalAttachments([...localAttachments.filter((p) => p.fileId !== fileId)]);
    if (onDeleteAttachment) onDeleteAttachment(customFieldId, fileId);
  };

  return (
    <>
      {isNoteDisplayed && (
        <Textarea
          my={2}
          color='neutral.800'
          value={note}
          onChange={(e) =>
            onChange({
              customFieldId,
              note: e.target.value,
            })
          }
          isDisabled={disabled}
        />
      )}
      <Flex marginTop={1}>
        <FileUploadButton
          addFile={onAddFile}
          disabled={disabled}
          getFileUploadUrls={getFileUploadUrls}
        />
        <Button
          size='sm'
          onClick={onClick}
          display='block'
          isDisabled={disabled}
          fontWeight='normal'
        >
          {isNoteDisplayed ? t('remark.delete') : t('remark.add')}
        </Button>
      </Flex>
      {attachmentFiles && (
        <MediaSwiperWrapper
          mediaFiles={attachmentFiles}
          showRemoveFileIcon={!disabled}
          removeFileHandler={onRemoveFile}
          slideDisplayMode='double'
        />
      )}
    </>
  );
};

export default CustomFieldNote;
