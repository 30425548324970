import useTranslation from '@/utils/i18n/useTranslation';
import { IconButton } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { FaCamera } from 'react-icons/fa';
import { FileUploadProps, useFileUploadHandler } from '.';

type FileUploadButtonProps = {
  disabled: boolean;
} & FileUploadProps;

const FileUploadButton = (props: FileUploadButtonProps) => {
  const { disabled, isMultiple = false } = props;
  const { acceptArray, handleFilesChange, handleDrop, resizeModal } = useFileUploadHandler(props);
  const { t_errors } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const zoneRef = useRef<HTMLElement | null>(null);

  const handleClick = () => inputRef.current?.click();

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const dropZone = zoneRef.current as HTMLElement;
    if (dropZone === null) throw new Error(t_errors('drop-zone-not-exist'));
    dropZone.addEventListener('dragover', handleDragOver);
    dropZone.addEventListener('drop', handleDrop);

    return () => {
      dropZone.removeEventListener('dragover', handleDragOver);
      dropZone.removeEventListener('drop', handleDrop);
    };
  }, [handleDrop, t_errors]);

  return (
    <>
      <IconButton
        size='sm'
        ml={'auto'}
        mr={0.5}
        ref={(e) => {
          zoneRef.current = e;
        }}
        onClick={handleClick}
        isDisabled={disabled}
        fontWeight='normal'
        aria-label={''}
      >
        {<FaCamera />}
      </IconButton>
      <input
        accept={acceptArray.join(',')}
        type='file'
        hidden
        onChange={(e) => handleFilesChange(e)}
        ref={(e) => {
          inputRef.current = e;
        }}
        onClick={(event): string => (event.currentTarget.value = '')}
        multiple={isMultiple}
      />
      {resizeModal}
    </>
  );
};

export default FileUploadButton;
